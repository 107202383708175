import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#90caf9",  // Primary color
      light: "#e3f2fd",  // Lighter variant of primary color
      dark: "#42a5f5",   // Darker variant of primary color
      contrastText: "#fff",  // Text color when using primary background
    },
    secondary: {
      main: "#f48fb1",
      light: "#f8bbd0",
      dark: "#ad1457",
      contrastText: "#000",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#4caf50",
    },
    background: {
      default: "#f5f5f5",  // Default page background
      paper: "#fff",       // Background for paper components
    },
    text: {
      primary: "#808081",   // Main text color
      secondary: "#757575", // Secondary text color
      disabled: "#9e9e9e",  // Text when disabled
    },
  }
});